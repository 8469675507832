/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { Step } from '../types';
import icon99 from '../images/icon-99.svg';
import logo from '../images/logo.png';

type HeaderProps = {
  step: Step
};

const Header: FC<HeaderProps> = ({ step }) => {
  return (
    <div className="content">
      {2 === step ? (
        <>
          <p className="tagline">Welcome to the Nines!</p>
          <h1>Join <img src={icon99} alt="99" /> Rewards</h1>
          <p className="subtitle">To get free access to our WiFi.</p>
          <div className="description small-margin">You'll be the first to know about <span className="icon-99">99</span> News and promotions, receive a treat just for signing up and more surprises throughout the year. </div>
        </>
      ) : (
        <>
          <h1 className="primary">
            Welcome to
            <img className="logo" src={logo} alt="Ninety Nine Restaurant & Pub" />
          </h1>
          <div className="description">Our WiFi service is provided as a bonus for <span className="icon-99">99</span> REWARDS members. Please enter your email address below to login or sign up for a free account.</div>
        </>
      )}
    </div>
  )
}

export default Header;