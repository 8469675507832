/**
 * External dependencies
 */
import { FC } from 'react';
import { useFormContext, Control } from 'react-hook-form';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { NumberField } from '.';

type DateFieldProps = {
  className?: string;
  control: Control;
};

const DateField: FC<DateFieldProps> = ({ className }) => {
  const { formState: { errors, isSubmitted }, getValues } = useFormContext();
  const separator = <span>/</span>;

  const invalid = errors.month || errors.day || errors.year;
  
  if (! invalid) {
    const values = getValues();
    const date = new Date();
    
    date.setFullYear(values.year, values.month - 1, values.day);
  }
  
  return (
    <>
    <div className={classnames(className, { invalid })}>
      <NumberField
        label="MM"
        name="month"
        required={true}
        min={1}
        max={12}
      />
      {separator}
      <NumberField
        label="DD"
        name="day"
        required={true}
        min={1}
        max={31}
      />
      {separator}
      <NumberField
        label="YYYY"
        name="year"
        required={true}
        min={1900}
        max={new Date().getFullYear()}
      />
    </div>
    {isSubmitted && invalid && (
      <p className="validation-error">Please enter a valid birthday date</p>
    )}
    </>
  );
}

export default DateField;