/**
 * External dependencies
 */
import { FC } from 'react';
import classnames from 'classnames';

type FormRowProps = {
  className?: string
  title?: string,
};

const FormRow: FC<FormRowProps> = ({
  className: passedClassName,
  title,
  children,
}) => {
  const className = classnames(
    'row',
    passedClassName,
  );
  
  return (
    <div className={className}>
      {title && (
        <div className="title">{title}</div>
      )}
      {children}
    </div>
  );
}

export default FormRow;