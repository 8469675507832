/**
 * External dependencies
 */
import { FC, SyntheticEvent } from 'react';
import { useFormContext } from 'react-hook-form';

/**
 * Internal dependencies
 */
import { FloatingLabelInput } from '.';

type NumberFieldProps = {
  addLeadingZero?: boolean;
  label: string;
  max: number;
  min: number;
  name: string;
  onComplete?: () => void;
  onReset?: () => void;
  required?: boolean;
  length?: number;
};

const NumberField: FC<NumberFieldProps> = ({
  label,
  max,
  min,
  name,
  required,
}) => {
  const { setValue } = useFormContext();

  const onInput = (e: SyntheticEvent<HTMLInputElement>) => {
  }
  
  const onKeyUp = (e: SyntheticEvent<HTMLInputElement>) => {
  }
  
  const onBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const field = e.target as HTMLInputElement;
    
    if (parseInt(field.value) < 10 && field.value.length === 1) {
      setValue(field.name, `0${field.value}`);
    }
  }

  return (
    <FloatingLabelInput
      label={label}
      name={name}
      type="number"
      rules={{
        required,
        min,
        max,
      }}
      onInput={onInput}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
    />
  );
}

NumberField.defaultProps = {
  required: true,
  addLeadingZero: true,
  length: 2,
};

export default NumberField;