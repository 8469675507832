/**
 * External dependencies
 */
import { FC, useState, InputHTMLAttributes, SyntheticEvent } from 'react';
import classnames from 'classnames';
import { useController, UseControllerProps } from 'react-hook-form';

type FloatingLabelInputProps = {
  label: string;
  type?: string;
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void;
} & UseControllerProps & InputHTMLAttributes<HTMLInputElement>;

const FloatingLabelInput: FC<FloatingLabelInputProps> = (props) => {
  const {
    label,
    onBlur,
    onChange,
    type,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
    ...otherProps
  } = props;
  
  const [active, setActive] = useState<boolean>(false);

  const controlerProps = {
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  };

  const {
    field: { onBlur: fieldOnBlur, ...field },
    fieldState: { invalid, error }
  } = useController(controlerProps);
  
  const fieldOnChange = field.onChange;
  
  field.onChange = (e: SyntheticEvent<HTMLInputElement>) : void => {
    const target = e.target as HTMLInputElement;

    if (target.value && ! active) {
      setActive(true);
    } else if (! target.value && active) {
      setActive(false);
    }
    
    onChange && onChange(e);
    fieldOnChange(e);
  }
  
  const wrapOnBlur = (e: SyntheticEvent<HTMLInputElement>) : void => {
    onBlur && onBlur(e);
    fieldOnBlur();
  }
  
  return (
    <div className={classnames('field-wrap', { invalid })}>
      <div className={classnames('floating-label', { active })}>
        <input
          {...field}
          type={type}
          onBlur={wrapOnBlur}
          {...otherProps}
        />
        <label>{label}</label>
      </div>
      {error && error.message && (
        <p className="validation-error">{error.message}</p>
      )}
    </div>
  );
}

FloatingLabelInput.defaultProps = {
  type: 'text',
}

export default FloatingLabelInput;