/**
 * External dependencies
 */
import { FC } from 'react';

import gift from '../images/gift.svg';
import triple from '../images/3X.png';
import medal from '../images/medal.svg';
import utensil from '../images/utensils.svg';
import icon99 from '../images/icon-99.svg';

const Rewards: FC = () => {

    return (
        <div className="rewards">
            <div className="title">
                <h3>Join <img src={icon99} alt="99" /> Rewards</h3>
                <h3>Today For:</h3>
            </div>

            <div className="rewards-wrap">
                <div className="reward">
                    <div className="icon">
                        <img width="28" height="25" src={triple} className="attachment-full size-full" alt="" decoding="async" loading="lazy" />
                    </div>
                    <div className="description">
                        <p className="is-style-tag1">A Surprise Reward Every Third Visit</p>
                        <p className="is-style-p3 has-primary-light-color">Receive a reward after every visit or online order of $9.99 or more.</p>
                    </div>
                </div>
                <div className="reward">
                    <div className="icon">
                        <img src={medal} className="attachment-full size-full" alt="" decoding="async" loading="lazy" />
                    </div>
                    <div className="description">
                        <p className="is-style-tag1">Exclusive News &amp; Rewards</p>
                        <p className="is-style-p3 has-primary-light-color">Be the first to know about events and get special offers.</p>
                    </div>
                </div>
                <div className="reward">
                    <div className="icon">
                        <img src={utensil} className="attachment-full size-full" alt="" decoding="async" loading="lazy" />
                    </div>
                    <div className="description">
                        <p className="is-style-tag1">A treat for signing Up</p>
                        <p className="is-style-p3 has-primary-light-color">Receive a free Petite Treat Dessert after your first visit.</p>
                    </div>
                </div>
                <div className="reward">
                    <div className="icon">
                        <img src={gift} className="attachment-full size-full" alt="" decoding="async" loading="lazy" />
                    </div>
                    <div className="description">
                        <p className="is-style-tag1">Birthday Dessert</p>
                        <p className="is-style-p3 has-primary-light-color">Enjoy a free dessert every year.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Rewards;