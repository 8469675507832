/**
 * External dependencies
 */
import sha256 from 'crypto-js/sha256';

const url = 'https://wifi2.99restaurants.com/';
const salt = 'gQY0MzdvN44PQvIx23yf8WfjzLaQCHBh';
const magicField = document.getElementById('magic-field') as HTMLInputElement;
const magic = magicField.value;

type Endpoint = 'check' | 'register';

export const makeRequest = async (endpoint: Endpoint, data: Record<string, any>) : Promise<Response> => {
  const token = sha256(salt + magic).toString();
  
  const formData = new FormData();
  
  for (const key in data) {
    formData.append(key, data[key]);
  }
  
  const response = await fetch(url + endpoint, {
    method: "POST",
    body: formData,
    headers: {
      'Magic': magic,
      'Authorization': `Bearer ${token}`
    }
  });
  
  return response;

}

type CheckEmailResponse = {
  success: boolean;
  registered?: boolean;
  errors?: Record<string, any>;
}

export const checkEmail = async (email: string) : Promise<CheckEmailResponse> => {
  const response = await makeRequest('check', { email });
  
  if ([200, 422].includes(response.status)) {
    try {
      const data = await response.json();
      
      if (undefined !== data.registered) {
        return {
          success: true,
          registered: !!data.registered,
        };
      } else if (422 === response.status) {
        return {
          success: false,
          errors: data,
        };
      }
    } catch (e) {}
  }
  
  return {
    success: true,
    registered: true,
  };
}

type RegisterResponse = true | Record<string, any>;

export const register = async (email: string, dateOfBirth: string) : Promise<RegisterResponse> => {
  const response = await makeRequest('register', {
    'userFields[email]': email,
    'userFields[dateOfBirth]': dateOfBirth,
  });
  
  if (201 === response.status) {
    return true;
  }
  
  if (422 === response.status) {
    try {
      const data = await response.json();
  
      const errors: Record<string, any> = {};
      
      if (data['userFields.email']) {
        errors.email = data['userFields.email'];
      }
      
      console.log(errors);
      

      return errors;
    } catch (e) {}
  }

  return true;
};

export const calculateAge = (dob: Date) : number => {
	const diff = Date.now() - dob.getTime();
	const age = new Date(diff);

	return Math.abs(age.getUTCFullYear() - 1970);
};
