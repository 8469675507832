/**
 * External dependencies
 */
import { useState, FC } from 'react';

/**
 * Internal dependencies
 */
import { Header, SubscribeForm, Rewards } from '.';
import { Step } from '../types'

const App: FC = () => {
  const [step, setStep] = useState<Step>(1);

  const mainForm = document.getElementById('main-form') as HTMLFormElement;

  return (
    <div className="site-inner">
      <Rewards />
      <div className="form-content">
        <Header step={step} />
        <SubscribeForm
          step={step}
          setStep={setStep}
          onSubscribe={() => { mainForm.submit() }}
        />
        <div className="terms">
          By connecting you are agreeing to our
          <br />
          <a
            href="https://www.99restaurants.com/wifi-terms-and-conditions/"
            target="_blank"
            rel="noreferrer"
          >
            terms and conditions
          </a>
          .
        </div>
      </div>

    </div>
  );
}

export default App;
