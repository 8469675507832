/**
 * External dependencies
 */
import { FormProvider, useForm } from 'react-hook-form';
import { useState, FC } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { register, checkEmail } from '../utils';
import { Step } from '../types';
import { FormRow, FloatingLabelInput, DateField } from '.';

type SubscribeFormProps = {
  onSubscribe?: () => void;
  step: Step,
  setStep: (step: Step) => void,
}

const emailPattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const SubscribeForm: FC<SubscribeFormProps> = ({
  onSubscribe,
  setStep,
  step,
}) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const formMethods = useForm();
  const {
    control,
    formState: { isValid, isSubmitted },
    handleSubmit,
    setError,
  } = formMethods;
  
  const onSubmit = handleSubmit(async (data) => {
    setProcessing(true);

    let redirect = false;

    if (1 === step) {
      const response = await checkEmail(data.email);
      
      if (response.success) {
        if (true === response.registered) {
          redirect = true;
        } else {
          setStep(2);
        }
      } else if (response.errors && response.errors.email) {
        setError('email', {
          type: 'pattern',
          message: emailMessage,
        });
      }
    } else {
      const dateOfBirth = `${data.year}-${data.month}-${data.day}`;
      
      const response = await register(data.email, dateOfBirth);

      if (true === response) {
        redirect = true;
      } else if (response.email) {
        setError('email', {
          type: 'pattern',
          message: emailMessage,
        });
      }
    }
    
    if (redirect && onSubscribe) {
      onSubscribe();
    }

    setProcessing(false);
  });
  
  const emailMessage = 'Please provide a valid email';

  return (
    <FormProvider {...formMethods}>
      <form className="subscribe-form" onSubmit={onSubmit} noValidate>
        {2 === step && (
          <FormRow title="Birthday*:" className="content-birthday">
          <DateField
            className="birthday-fields"
            control={control}
          />
          </FormRow>
        )}
        <FormRow>
          <FloatingLabelInput
            label="Your e-mail address"
            name="email"
            control={control}
            rules={{
              required: {
                value: true,
                message: emailMessage
              },
              pattern: {
                value: emailPattern,
                message: emailMessage
              }
            }}
          />
        </FormRow>
        <button
          className={classnames('btn', 'submit-button', { 'is-loading': processing })}
          type="submit"
          disabled={processing || (isSubmitted && ! isValid)}
        >
          Access WiFi
        </button>
      </form>
    </FormProvider>
  )
};

export default SubscribeForm;